import {verifiedFetch} from '@github-ui/verified-fetch'
import {XIcon} from '@primer/octicons-react'
import {Box, Flash, IconButton, LinkButton} from '@primer/react'
import type {BetterSystemStyleObject} from '@primer/react/lib-esm/sx'
import {useState} from 'react'
import {linkButtonSx} from '../utilities/styles'

export default function PublishBanners({
  showPublishActionBanner,
  releasePath,
  dismissActionNoticePath,
  sx,
}: {
  showPublishActionBanner: boolean
  releasePath: string
  dismissActionNoticePath: string
  sx?: BetterSystemStyleObject
}) {
  const [hidden, setHidden] = useState(false)

  const onDismissPublishAction = () => {
    verifiedFetch(dismissActionNoticePath, {method: 'POST'})
    setHidden(true)
  }

  return showPublishActionBanner ? (
    <Flash sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3, ...sx}} hidden={hidden}>
      {showPublishActionBanner && <Box sx={{flexGrow: 1}}>You can publish this Action to the GitHub Marketplace</Box>}
      <LinkButton href={releasePath} sx={{fontSize: 0, mr: 2, ...linkButtonSx}}>
        Draft a release
      </LinkButton>
      <IconButton
        icon={XIcon}
        aria-label="Dismiss"
        onClick={showPublishActionBanner ? onDismissPublishAction : () => {}}
        sx={{backgroundColor: 'transparent', border: 0, pr: 0}}
        title="Dismiss"
      />
    </Flash>
  ) : null
}

try{ PublishBanners.displayName ||= 'PublishBanners' } catch {}