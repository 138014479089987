import {usePortalTooltip} from '@github-ui/portal-tooltip/use-portal-tooltip'
import useIsMounted from '@github-ui/use-is-mounted'
import type {Icon} from '@primer/octicons-react'
import {CheckIcon, CopyIcon} from '@primer/octicons-react'
import type {SxProp, TooltipProps} from '@primer/react'
import {Box, IconButton} from '@primer/react'
import React from 'react'
import {Tooltip} from '@primer/react/next'

import {copyText} from './copy'

const copyConfirmationMsDelay = 2000

export interface CopyToClipboardButtonProps extends SxProp {
  /**
   * Octocon that is displayed on the copy button
   *
   * @default CopyIcon
   */
  icon?: Icon
  /**
   * Size of the button, passed to IconButton
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Optional callback that is invoked when the user clicks the copy button
   */
  onCopy?: () => void
  /**
   * Text that will be copied to the clipboard
   */
  textToCopy: string
  /**
   * Props that will be applied to tooltips
   */
  tooltipProps?: TooltipProps
  /**
   * Text that will be displayed in the tooltip
   */
  ariaLabel?: string | null
  /**
   * If the button should be accessible or not
   */
  accessibleButton?: boolean
  /**
   * Whether or not to use the portal tooltip
   */
  hasPortalTooltip?: boolean
}

export function CopyToClipboardButton({
  icon = CopyIcon,
  size = 'medium',
  onCopy,
  sx,
  textToCopy,
  tooltipProps,
  ariaLabel,
  accessibleButton,
  hasPortalTooltip = false,
}: CopyToClipboardButtonProps) {
  const [copied, setCopied] = React.useState(false)
  const isMounted = useIsMounted()
  const onClickCopy = () => {
    setCopied(true)
    void copyText(textToCopy)
    onCopy?.()
    setTimeout(() => isMounted() && setCopied(false), copyConfirmationMsDelay)
  }

  const label = copied ? 'Copied!' : ariaLabel ?? `Copy ${textToCopy} to clipboard`

  if (hasPortalTooltip) {
    return (
      <PortalTooltipCopyButton
        label={label}
        textToCopy={textToCopy}
        copied={copied}
        onClickCopy={onClickCopy}
        tooltipProps={tooltipProps}
        sx={{...sx}}
      />
    )
  }

  return (
    <Tooltip text={label} aria-label={label} {...tooltipProps} sx={{position: 'absolute'}}>
      <IconButton
        aria-label={label}
        icon={copied ? CheckIcon : icon}
        variant="invisible"
        size={size}
        tabIndex={accessibleButton === false ? -1 : 0}
        className={copied ? 'color-fg-success' : undefined}
        sx={{...sx}}
        onClick={onClickCopy}
      />
    </Tooltip>
  )
}

interface PortalTooltipCopyButtonProps extends CopyToClipboardButtonProps {
  /**
   * Text that will be displayed in the tooltip
   */
  label: string
  /**
   * Copy state
   */
  copied: boolean
  /**
   * Function to call when copy button is clicekd
   */
  onClickCopy: () => void
}

function PortalTooltipCopyButton({
  icon = CopyIcon,
  size = 'medium',
  label,
  accessibleButton,
  copied,
  onClickCopy,
  tooltipProps,
  sx,
}: PortalTooltipCopyButtonProps) {
  const contentRef = React.useRef<HTMLDivElement>(null)
  const [buttonContentProps, buttonTooltipElement] = usePortalTooltip({
    contentRef,
    'aria-label': copied ? 'Copied!' : label,
    ...tooltipProps,
  })

  return (
    <Box ref={contentRef} {...buttonContentProps}>
      <IconButton
        aria-label={label}
        icon={copied ? CheckIcon : icon}
        variant="invisible"
        size={size}
        tabIndex={accessibleButton === false ? -1 : 0}
        className={copied ? 'color-fg-success' : undefined}
        sx={{...sx}}
        onClick={onClickCopy}
      />
      {buttonTooltipElement}
    </Box>
  )
}

try{ CopyToClipboardButton.displayName ||= 'CopyToClipboardButton' } catch {}
try{ PortalTooltipCopyButton.displayName ||= 'PortalTooltipCopyButton' } catch {}